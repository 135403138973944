<template>
	<div v-loading="fullscreenLoading">
		<!--收货地址-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text">Address</div>

			<div clsas="address-list">
				<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						Add Address
					</div>
				</div>

				<div class="address-item" v-for="(item, key) in memberAddress" :key="item.id" :class="addressId == item.id ? 'active' : ''"
				 v-if="key < 3 || (addressShow && key >= 3)">
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">Edit</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm title="Do you want to delete the address？" @onConfirm="deleteMemberAddress(item.id)">
									<div slot="reference">Delete</div>
								</el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.full_address }} {{ item.address }}</div>
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					More Address
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--购买虚拟类商品需填写您的手机号-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">To purchase virtual goods, you need to fill in your mobile phone number to facilitate businesses to contact you</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item label="Phone">
					<el-input placeholder="Please input phone number" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input>
				</el-form-item>
			</el-form>
		</div>

		<!--收货地址添加-->
		<el-dialog :title="addressForm.id == 0 ? 'add address' : 'edit address'" :visible.sync="dialogVisible" width="32%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
				<el-form-item label="Name" prop="name">
					<el-input v-model="addressForm.name" placeholder="Consignee Name"></el-input>
				</el-form-item>

				<el-form-item label="Phone" prop="mobile">
					<el-input v-model="addressForm.mobile" maxlength="11" placeholder="Consignee mobile number"></el-input>
				</el-form-item>

				<el-form-item label="Phone">
					<el-input v-model="addressForm.telephone" placeholder="Consignee's fixed telephone number (optional)"></el-input>
				</el-form-item>

				<el-form-item class="area" label="Area" prop="area">
					<el-row :gutter="10">
						<el-col :span="7">
							<el-select prop="province" ref="province" v-model="addressForm.province_id" @change="getAddress(1)" placeholder="Please select">
								<el-option label="Please select" value="0"></el-option>
								<el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)" placeholder="Please select">
								<el-option label="Please select" value="0"></el-option>
								<el-option v-for="item in cityArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="district" prop="district" v-model="addressForm.district_id" placeholder="Please select">
								<el-option label="Please select" value="0"></el-option>
								<el-option v-for="item in districtArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>

				<el-form-item label="Address" prop="address">
					<el-input v-model="addressForm.address" placeholder="Locate community, street and office building"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">Cancel</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">Sure</el-button>
			</span>
		</el-dialog>

		<!--使用余额-->
		<div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && balance_show == 1">
			<div class="block-text">Use balance</div>

			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">Unused balance</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">Use balance</div>
				<div class="clear"></div>
			</div>
		</div>


		<!-- 配送方式 -->
		<div class="item-block padd-bom-20" v-if="orderPaymentData.is_virtual == 0">
			<div class="pay-type-list">Distribution mode
				<span class="distribution" v-if="orderPaymentData.delivery_type == 'store' && orderCreateData.delivery.store_name">{{ orderCreateData.delivery.store_name }}</span>
			</div>
			<div v-if="orderPaymentData.shop_goods_list.express_type.length > 0">
				<div class="pay-type-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
				 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem)" :class="orderPaymentData.delivery_type == deliveryItem.name ? 'active' : ''"
				 v-if="deliveryItem.name != 'local'">
					{{ deliveryItem.title }}
				</div>
			</div>
			<div v-else-if="memberAddress.length == 0">
				<div class="box ns-text-color">Please add the receiving address first</div>
			</div>
			<div v-else>
				<div class="box ns-text-color">The merchant has not configured the delivery method</div>
			</div>

		</div>

		<!--商品信息-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="70%">Goods</td>
						<td width="15%">Price</td>
						<td width="15%">Number</td>
					</tr>
				</table>
			</div>
		</div>

		<div>
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in orderPaymentData.shop_goods_list.goods_list" :key="goodsIndex">
							<td width="70%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<div class="goods-name">{{ goodsItem.sku_name }}</div>
										</router-link>
										<!-- 规格 -->
										<div class="goods-spec" v-if="goodsItem.sku_spec_format">
											<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '; ' : '' }}</span>
										</div>
									</div>
								</div>
							</td>
							<td width="15%" class="goods-price">₵{{ goodsItem.price }}</td>
							<td width="15%" class="goods-num">{{ goodsItem.num }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>




		<!--Discount-->
		<div class="item-block">
			<div class="block-text">Store activities</div>
			<div class="order-cell platform-coupon">
				<span class="ns-text-color">Group buying can buy by {{ orderPaymentData.groupbuy_info.groupbuy_price }} at limit {{ orderPaymentData.groupbuy_info.buy_num }} piece </span>
			</div>
		</div>

		<!-- 买家留言 -->
		<div class="item-block padd-bom-10">
			<div class="block-text">Buyer message</div>
			<el-input rows="3" type="textarea" placeholder="It is recommended to coordinate with the merchant before leaving a message" v-model="orderCreateData.buyer_message" class="buyer-message"
			 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
		</div>

		<!-- 总计 -->
		<div class="item-block">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">Price：</td>
						<td align="left">₵{{ orderPaymentData.goods_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">Freight：</td>
						<td align="left">₵{{ orderPaymentData.delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">Tax：</td>
						<td align="left">₵{{ orderPaymentData.invoice_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">Discount：</td>
						<td align="left">₵{{ orderPaymentData.promotion_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">Balance：</td>
						<td align="left">₵{{ orderPaymentData.balance_money | moneyFormat }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<!--结算-->
		<div class="item-block">
			<div class="order-submit">
				<div class="order-money">
					Total {{ orderPaymentData.goods_num }}，Money ：
					<div class="ns-text-color">₵{{ orderPaymentData.pay_money | moneyFormat }}</div>
				</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate">Buy Now</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!--配送方式-->
		<el-dialog title="Select store" :visible.sync="dialogStore" width="50%">
			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="Name" width="160"></el-table-column>
				<el-table-column prop="store_address" label="Address"></el-table-column>
				<el-table-column prop="open_date" label="Business Hours"></el-table-column>
			</el-table>
		</el-dialog>

		<!-- 配送方式 外卖 -->
		<el-dialog title="Time of delivery" :visible.sync="deliveryTime" width="400px">
			<el-form status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="Time of delivery">
					<el-col :span="11">
						<el-time-picker format="HH:mm" value-format="HH:mm" placeholder="select time" :value="time" v-model="time" start="09:01"
						 end="21:01" @change="bindTimeChange"></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="">
					<el-col :span="20">
						<div class="align-right">
							<el-button size="small" @click="deliveryTime = false">Close</el-button>
							<el-button type="primary" size="small" @click="setDeliveryTime(siteDelivery.site_id)">Sure</el-button>
						</div>
					</el-col>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 支付密码 -->
		<el-dialog title="Balance" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>For your account security, please set your payment password first</p>
				<p>You can set it in "member center", "account security" and "payment password"</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">Temporarily not set</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">Set now</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="Payment password" class="pay-password-item">
					<!--添加一个不可见的input,欺骗浏览器自动填充-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">Forget</p>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import detail from './payment_groupbuy.js';
	export default {
		name: 'groupbuy_payment',
		components: {
			PicZoom
		},
		mixins: [detail]
	};
</script>

<style lang="scss" scoped>
	@import './payment_groupbuy.scss';
</style>
